<template>
    <div>
        <category-node :bus="bus" :parent="0" :node="0" ></category-node>
    </div>
</template>

<script>

    import Vue from 'vue'
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import CategoryNode from './CategoryNode';

    Vue.use(VueAxios, axios);

    export default {
        name: 'CategoryTree',
        components: {CategoryNode},
        data: function () {
            return {
                bus: new Vue(),
            }
        },
    }
</script>

<style src="./CategoryTree.css"></style>
