<template>
    <div>
        <div v-bind:class="{ 'webbar rootnode funselected': parent === 0, 'webbar branchnode funselected': parent !== 0  }"
             v-if="node !== 0">
            <div>
                <div class="nav navbar-nav">
                    <span :id="categoryNode.id"></span>
                    <span :id="categoryNode.id" class="glyphicon glyphicon-tree-deciduous" v-bind:class="{
                        markerko: categoryNode.failCount > 0,
                        markerok: categoryNode.failCount === 0 }"></span>
                    {{ categoryNode.title }}
                </div>
                <div class="nav navbar-nav navbar-right">
                    <div class="up glyphicon glyphicon-chevron-down" v-on:click="toggleCategory(categoryNode.id)"
                         v-if="categoryNode.children !== 0"></div>
                    <a :href="'/category/category-edit?parent=' + node" v-if="categoryUrls.length === 0">
                        <div class="glyphicon glyphicon-plus" title="Přidání kategorie"></div>
                    </a>
                    <a :href="'/category/category-edit/' + categoryNode.id + '?parent=' + parent">
                        <div class="glyphicon glyphicon glyphicon-pencil" title="Editace kategorie"></div>
                    </a>
                    <a :href="'/category-url/category-url-edit/?parent=' + categoryNode.id"
                       v-if="categoryNode.children === 0">
                        <div class="runnode glyphicon glyphicon-file" title="Vložit stránku ke kontrole"></div>
                    </a>
                    <div class="runnode glyphicon glyphicon-triangle-right" title="Pustit kontrolu"
                         v-on:click="testCategory(categoryNode.id)"></div>
                </div>
            </div>

        </div>
        <div v-if="node === 0 || (node !== 0 && categoryNode !== null && categoryNode.open === true)">
            <div class="webcontent" v-if="node !== 0">
                <div v-for="category in categories" v-bind:key="category.id">
                    <category-node :bus="bus" v-bind:key="category.id"
                                   :node="category.id" :categoryNode="category" :parent="node"></category-node>
                </div>
            </div>
            <div v-if="node === 0">
                <div v-for="category in categories" v-bind:key="category.id">
                    <category-node :bus="bus" v-if="parent === 0" v-bind:key="category.id"
                                   :node="category.id" :categoryNode="category" :parent="node"></category-node>
                </div>
            </div>
        </div>

        <div class="webcontent">
            <div v-for="categoryUrl in categoryUrls" v-bind:key="categoryUrl.id">
                <div class="webbar funselected page">
                    <div class="nav navbar-nav">
                        <span :id="categoryUrl.id"></span>
                        <span :id="categoryUrl.id" class="glyphicon glyphicon-tree-deciduous"
                              v-bind:class="{ markerko: categoryUrl.failCount > 0,
                              markerok: categoryUrl.failCount === 0 && categoryUrl.hasPattern === true,
                              markerunknown: categoryUrl.failCount === 0 && categoryUrl.hasPattern === false, }">
                        </span>
                        {{ categoryUrl.url }}
                    </div>
                    <div class="nav navbar-nav navbar-right">
                        <a :href="'/category-url-patterns/category-url-patterns-edit?categoryUrlId=' + categoryUrl.id">
                            <div class="glyph glyphicon glyphicon-th-large showtip" title="Editace vzorů"></div>
                        </a>
                        <a :href="'/category-url/category-url-edit/'+ categoryUrl.id+'?parent=' + categoryNode.id">
                            <div class="glyphicon glyphicon glyphicon-pencil" title="Editace stránky"></div>
                        </a>
                        <div class="runnode glyphicon glyphicon-triangle-right" title="Pustit kontrolu"
                             v-on:click="testCategoryUrl(categoryUrl.id)"></div>
                        <a :href="'/category-url/log/'+ categoryUrl.id">
                            <div class="runnode glyphicon glyphicon-book" title="Log kontrol"></div>
                        </a>
                    </div>
                </div>

            </div>
        </div>

    </div>

</template>

<script>
    import axios from 'axios'

    export default {
        name: "category-node",
        props: {
            bus: Object,
            node: Number,
            parent: Number,
            categoryNode: {}
        },
        data: function () {
            return {
                categories: {},
                categoryUrls: [],
            }
        },
        methods: {
            getCategories: function (parentId) {
                axios
                    .get('./?do=getCategories', {
                        params: {
                            parentId: parentId
                        }
                    })
                    .then((response) => {
                        if (response.data.categories !== undefined) {
                            this.$set(this, 'categories', response.data.categories);
                        }
                    });
            },
            testCategoryUrl: function (categoryUrlId) {
                axios
                    .get('./?do=testCategoryUrl', {
                        params: {
                            categoryUrlId: categoryUrlId
                        }
                    })
                    .then(() => {
                        this.getCategoryUrls(this.categoryNode.id);
                    });
            },
            testCategory: function (id) {
                let nodeId = this.node !== 0 ? this.node : null;
                if (nodeId === id) {
                    if (this.categoryUrls.length > 0) {
                        let categoryUrls = this.categoryUrls;

                        categoryUrls.map((categoryUrl) => {
                            this.testCategoryUrl(categoryUrl.id);
                        });
                    } else if (this.categories.length > 0) {
                        this.categories.map((category) => {
                            this.bus.$emit('controlCategoryUrl', category.id);
                        });
                    }
                }
            },
            toggleCategory: function (id) {
                axios
                    .get('./?do=toggleCategory', {
                        params: {
                            categoryId: id
                        }
                    })
                    .then((response) => {
                        if (response.data.categories !== undefined) {
                            this.$set(this, 'categories', response.data.categories);
                        }
                    });
                this.getCategories(id);
                this.bus.$emit('eventToggle', this.parent);
            },
            getCategoryUrls: function (categoryId) {
                axios
                    .get('./?do=getCategoryUrls', {
                        params: {
                            categoryId: categoryId
                        }
                    })
                    .then((response) => {
                        if (response.data.categoryUrls !== undefined) {
                            this.$set(this, 'categoryUrls', response.data.categoryUrls);
                            this.bus.$emit('refresh', this.parent);
                        }
                    });
            },
            toggle(parentId) {
                if (parentId === this.node) {
                    this.refresh();
                }
            },
            refresh() {
                let nodeId = this.node !== 0 ? this.node : null;
                this.getCategories(nodeId);
            }
        },
        mounted() {
            this.bus.$on('eventToggle', this.toggle);
            this.bus.$on('refresh', this.refresh);
            this.bus.$on('controlCategoryUrl', this.testCategory);
        },
        created: function () {
            this.refresh();
            if (this.node !== 0 && this.categoryNode !== null && this.categoryNode.children === 0) {
                this.getCategoryUrls(this.categoryNode.id);
            }
        }
    };
</script>
